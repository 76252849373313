import { mediaFragment } from './Search/mediaFragment';
import affiliatesFragment from './Storefront/affiliates';
import facetsLimitedFragment from './Storefront/facets/limited';

const fragment = `
  fragment ProfileProperties on Storefront {
    id
    vendorId
    accountId
    accountStatus
    name
    displayId
    isTestData
    ivolScoreId
    adTier
    claimedStatus
    marketCode
    movedProfileId
    purchaseStatus
    vendorTier
    addOns {
      code
    }
    affiliates {
      id
      name
      description
      singular {
        slug
        term
      }
      plural {
        slug
        term
      }
      isSelected
      ...AffiliatesRecurse
    }
    categories {
      id
      code
      name
      displayName
    }
    categoryInfo {
      plural {
        slug
        term
      }
    }
    facets {
      id
      name
      ...FacetsRecurseLimited
    }
    location {
      address {
        address1
        address2
        city
        state
        postalCode
        latitude
        longitude
        isPublic
      }
      serviceArea
    }
    logo {
      url
    }
    mediaSummary {
      total
      media {
        ...MediaItems
      }
    }
    reviewSummary {
      count
      overallRating
    }
    salesProfiles {
      vendorTier
      marketCode
      addOns {
        code
        name
      }
    }
    settings {
      pricing
      enhancedRFQ
    }
    siteUrls {
      siteId
      siteName
      uri
      relativeUri
    }
    statistics {
      ivolScore
    }
    storefrontCard {
      height
      id
      mediaType
      url
      width
    }
}
${affiliatesFragment}
${facetsLimitedFragment}
${mediaFragment}
`;

export default fragment;
