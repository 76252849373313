import { useTrackStorefrontInteraction } from '@hooks/useTrackStorefrontInteraction';
import type { IconName } from '@xo-union/dist-iconography';
import Icon from '@xo-union/tk-component-icons';
import { DisplayButton } from '@xo-union/tk-ui-links';
import { Body1, Body2, Subhead } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import React, {
	type DetailedHTMLProps,
	type FC,
	type HTMLAttributes,
	useCallback,
	useMemo,
	useState,
} from 'react';
import type { DetailedPricingPackage } from '../../jotai/storefront/detailed-pricing';
import Styles from './package.scss';

interface PackageRowProps {
	detail: string;
	icon: IconName;
	iconColor: 'green' | 'black';
	expanded?: boolean;
	index: number;
}

const PackageRow = ({
	detail,
	icon,
	iconColor,
	expanded,
	index,
}: PackageRowProps) => {
	const color = iconColor === 'green' ? '#008A05' : '#000000';
	return (
		<div
			className={classNames(Styles.detailRow, {
				[Styles.hide]: !expanded && index > 3,
			})}
		>
			<div className={Styles.detailIcon}>
				<Icon name={icon} size="sm" color={color} />
			</div>
			<Body1 className={Styles.detailRowText}>{detail}</Body1>
			<Body2 className={Styles.detailRowTextBvc}>{detail}</Body2>
		</div>
	);
};

interface SeeAllProps {
	expanded: boolean;
	toggle: () => void;
}

const SeeAll = ({ expanded, toggle }: SeeAllProps) => {
	const text = expanded ? 'See less' : 'See all';
	return (
		<div className={classNames(Styles.detailRow, Styles.seeAll)}>
			<div className={Styles.detailIcon} />
			<DisplayButton
				type="button"
				onClick={toggle}
				className={Styles.seeAllText}
			>
				{text}
			</DisplayButton>
		</div>
	);
};

interface PricingDetailProps {
	pkg: DetailedPricingPackage;
}

const PackageBody: FC<PricingDetailProps> = ({ pkg }) => {
	const [expanded, setExpanded] = useState(false);

	const toggleExpanded = useCallback(() => {
		setExpanded((value) => !value);
	}, []);

	return (
		<div className={Styles.detail}>
			{pkg.serviceDuration ? (
				<PackageRow
					detail={pkg.serviceDuration}
					icon="time"
					iconColor="black"
					index={-1}
				/>
			) : null}
			{pkg.services.map((detail, index) => {
				const key = `${detail.replace(' ', '-')}-${index}`;
				return (
					<PackageRow
						key={key}
						detail={detail}
						icon={'checkmark'}
						iconColor="green"
						index={index}
						expanded={expanded}
					/>
				);
			})}
			{pkg.services.length > 4 && (
				<SeeAll toggle={toggleExpanded} expanded={expanded} />
			)}
		</div>
	);
};

interface PackageHeadingsProps {
	collapse?: boolean;
	mobile?: true;
	toggleCollapse?: () => void;
	packageName?: string;
	startingAt?: string | null;
}

const PackageHeading: FC<PackageHeadingsProps> = ({
	packageName,
	startingAt,
	mobile,
	toggleCollapse,
	collapse,
}) => {
	const props: DetailedHTMLProps<
		HTMLAttributes<HTMLDivElement>,
		HTMLDivElement
	> = useMemo(() => {
		if (!mobile) {
			return {
				className: classNames([
					Styles.packageHeading,
					Styles.packageHeadingDesktop,
				]),
			};
		}

		return {
			className: classNames([
				Styles.packageHeading,
				Styles.packageHeadingMobile,
				{ [Styles.packageHeadingCollapse]: collapse },
			]),
			role: 'button',
			tabIndex: 0,
			onClick: toggleCollapse,
			onKeyPress: toggleCollapse,
			'aria-expanded': !collapse,
		};
	}, [mobile, toggleCollapse, collapse]);

	const caret: React.ComponentProps<typeof Icon>['name'] = collapse
		? 'caret_down'
		: 'caret_up';

	return (
		<div {...props}>
			<div className={Styles.packageHeadingContainer}>
				{packageName && (
					<Body1 className={Styles.packageName} bold>
						{packageName}
					</Body1>
				)}
				{startingAt && (
					<div className={Styles.startingPriceContainer}>
						<Subhead className={Styles.packageStartingCost} as="span" bold>
							{startingAt}
						</Subhead>{' '}
						<Body2 as="span">starting price</Body2>
					</div>
				)}
			</div>
			{mobile && <Icon name={caret} size="md" className={Styles.icon} />}
		</div>
	);
};

export interface PackageProps {
	initialCollapse: boolean;
	pkg: DetailedPricingPackage;
}

export const Package = ({ initialCollapse, pkg }: PackageProps) => {
	const [collapse, setCollapse] = useState(initialCollapse);
	const track = useTrackStorefrontInteraction('Packages');

	const toggleCollapse = useCallback(() => {
		setCollapse((value) => !value);
		track('Click arrow', {
			action: collapse ? 'Expand' : 'Collapse',
		});
	}, [track, collapse]);

	return (
		<div className={Styles.packageContainer}>
			<div className={Styles.packageWrapper}>
				<PackageHeading
					mobile
					collapse={collapse}
					toggleCollapse={toggleCollapse}
					packageName={pkg.title}
					startingAt={pkg.price}
				/>
				<PackageHeading packageName={pkg.title} startingAt={pkg.price} />
				<div
					className={classNames([
						Styles.packageBodyWrapperMobile,
						{ [Styles.packageBodyWrapperMobileOpen]: !collapse },
					])}
				>
					<div className={Styles.packageBody}>
						<PackageBody pkg={pkg} />
					</div>
				</div>
			</div>
		</div>
	);
};
