import { useDetailedPricing } from '@hooks/use-detailed-pricing';
import { useLiteStorefront } from '@hooks/use-lite-storefront';
import { useAppSelector } from '@redux/hooks';
import { Body1 } from '@xo-union/tk-ui-typography';
import React from 'react';
import { AwardItems } from '../../pages/Storefront/components/award-items/AwardItems';
import Styles from './styles.scss';

export const AboutAwards = () => {
	const isInDetailedPricing = useDetailedPricing();
	const vendor = useAppSelector((state) => state.vendor.vendor);
	const isLiteStorefront = useLiteStorefront();

	if (!isInDetailedPricing || !vendor?.awards.length || isLiteStorefront)
		return null;

	return (
		<div className={Styles.container}>
			<Body1 bold>Awards</Body1>
			<div className={Styles.awardsContainer}>
				<AwardItems vendor={vendor} />
			</div>
		</div>
	);
};
