import * as React from 'react';

export const HighlightedRequestIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="auto"
		height="100%"
		viewBox="0 0 60 60"
		fill="none"
		preserveAspectRatio="xMidYMid slice"
	>
		<title>Circle Message</title>
		<path
			d="M33.985 13.0239C45.9165 19.0027 51.3137 32.4645 45.9922 43.1391C40.7344 53.8245 26.7662 57.5682 14.8387 51.5966C2.90719 45.6178 -2.48994 32.1559 2.83148 21.4814C8.14192 10.8705 22.117 7.05617 33.985 13.0239Z"
			fill="#FBBB03"
		/>
		<path
			d="M55.6811 18.7629C53.8538 10.9475 44.6785 6.37064 35.1903 8.53787C25.7022 10.7051 19.4917 18.798 21.3189 26.6099C22.0243 29.6257 25.8844 33.2687 25.8844 33.2687C25.8844 35.5412 22.5258 38.7882 21.5699 39.6663C21.4719 39.7563 21.5239 39.9166 21.6564 39.9288C23.1337 40.0644 29.1258 40.3654 32.9233 36.8349C35.2837 37.1545 39.2714 37.4145 41.8097 36.8349C51.2978 34.6677 57.5083 26.5783 55.6811 18.7629Z"
			fill="#FF44CB"
		/>
	</svg>
);
