import { useConversationUrl } from '@hooks/use-conversation-url';
import { useAppSelector } from '@redux/hooks';
import { Button, Link } from '@xo-union/tk-component-buttons';
import React from 'react';
import RfqModal from '../../pages/Storefront/containers/InlineRFQ/components/RfqModal/RfqModal';
import { useInlineRfqForm } from '../../pages/Storefront/containers/InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';

export const AboutCta = () => {
	const vendorId = useAppSelector((state) => state.vendor.vendor?.id);
	const conversationUrl = useConversationUrl(vendorId);

	const localFormContext = useInlineRfqForm({
		freeTextLabel:
			'Say hello and ask a question about style, budget or availability.',
		initiator: 'About CTA',
		headerText: 'Start the convo',
	});

	if (conversationUrl) {
		return (
			<Link color="secondary" href={conversationUrl} size="md">
				View Conversation
			</Link>
		);
	}

	return (
		<>
			<RfqModal context={localFormContext} />
			<Button
				color={'secondary'}
				onClick={localFormContext.openModal}
				size="md"
				data-testid="about-cta-message-vendor"
			>
				Message Vendor
			</Button>
		</>
	);
};
