import Icon from '@xo-union/tk-component-icons';
import { Body1, Body2 } from '@xo-union/tk-ui-typography';
import React, { type ComponentProps } from 'react';
import { Link } from 'react-scroll';
import Styles from '../styles.scss';

interface Props {
	icon: ComponentProps<typeof Icon>['name'];
	text: string;
	textWrapper?: 'body1' | 'body2';
	linkHref?: string;
}

export const Highlight = ({
	icon,
	text,
	textWrapper = 'body1',
	linkHref,
}: Props) => {
	const BodyComponent = textWrapper === 'body1' ? Body1 : Body2;

	return (
		<div className={Styles.highlight}>
			<Icon name={icon} size="md" className={Styles.highlightImage} />
			<BodyComponent className={Styles.highlightText}>
				{linkHref ? (
					<Link
						className={Styles.link}
						href={linkHref}
						to={linkHref.replace('#', '')}
						smooth
						spy
						isDynamic
						offset={-49}
					>
						{text}
					</Link>
				) : (
					text
				)}
			</BodyComponent>
		</div>
	);
};
