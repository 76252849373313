import { useAppSelector } from '@redux/hooks';
import { Img } from '@xo-union/tk-component-picture';
import { Body1, Body2, Caption } from '@xo-union/tk-ui-typography';
import React from 'react';
import { NO_IMAGE_PATH } from '../../../pages/Storefront/components/Media/constants';
import Styles from '../styles.scss';

export const Spotlight = () => {
	const vendor = useAppSelector((state) => state.vendor.vendor);

	return (
		<div className={Styles.spotlightContainer}>
			<div className={Styles.spotlight}>
				{vendor?.bio?.photo?.url ? (
					<Img
						className={Styles.spotlightImage}
						src={vendor.bio.photo.url}
						alt="Vendor"
						height={90}
						width={90}
						smartCrop
					/>
				) : (
					<div className={Styles.spotlightWrapper}>
						<div className={Styles.bioPhoto}>
							<Img
								className={Styles.spotlightNoImage}
								src={NO_IMAGE_PATH}
								alt="None"
							/>
							<Caption size="sm">No photo yet</Caption>
						</div>
					</div>
				)}
				<div className={Styles.spotlightContent}>
					<Body1 bold className={Styles.bioName}>
						{vendor?.bio?.name ? vendor.bio.name : vendor?.name}
					</Body1>
					{vendor?.bio?.name && (
						<Body2 className={Styles.spotlightTitle}>
							{vendor?.bio.displayRole}
						</Body2>
					)}
				</div>
			</div>
		</div>
	);
};
