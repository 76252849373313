import { BOW_BADGE, BOW_FACET_ID } from '@components/vendorCard/constants';
import { useAppSelector } from '@redux/hooks';
import { StatusBadge } from '@xo-union/tk-component-badges';
import React, { useMemo } from 'react';
import Styles from './bow-expanded-badge.scss';

const useBowAwardsCount = () => {
	const vendorRaw = useAppSelector((state) => state.vendor.vendorRaw);

	return useMemo(() => {
		if (!vendorRaw?.affiliates?.length) {
			return 0;
		}

		const bowAffiliates = vendorRaw.affiliates.find(
			(affiliate) => affiliate.id === BOW_FACET_ID,
		);
		const bowAwards =
			bowAffiliates?.affiliates?.filter(
				(affiliate) =>
					affiliate.name && !affiliate.name.includes('Hall of Fame'),
			) || [];

		return bowAwards.length;
	}, [vendorRaw]);
};

export const BestOfWeddingsExpandedBadge = () => {
	const awardCount = useBowAwardsCount();

	if (awardCount <= 0) {
		return null;
	}

	return (
		<span className={Styles.expandedBadge}>
			<StatusBadge icon={BOW_BADGE.icon} type={BOW_BADGE.type} size="md">
				{`${BOW_BADGE.label} (${awardCount}x)`}
			</StatusBadge>
		</span>
	);
};
