import { IconButton } from '@xo-union/tk-component-icons';
import type { NavigationButtonProps } from '@xo-union/ui-carousel';
import classNames from 'classnames';
import React from 'react';
import Styles from './carousel-buttons.scss';

export const NextButton = ({
	className,
	onClick,
	...props
}: NavigationButtonProps) => {
	return (
		<div className={Styles.iconWrapper} aria-roledescription="next button">
			<IconButton
				className={classNames(className, Styles.nextButton)}
				{...props}
				name="caret_right"
				onClick={onClick}
				size="md"
			/>
		</div>
	);
};

export const PrevButton = ({
	className,
	onClick,
	...props
}: NavigationButtonProps) => {
	return (
		<div className={Styles.iconWrapper} aria-roledescription="previous button">
			<IconButton
				className={classNames(className, Styles.previousButton)}
				{...props}
				name="caret_left"
				onClick={onClick}
				size="md"
			/>
		</div>
	);
};
