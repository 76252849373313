import { Img } from '@xo-union/tk-component-picture';
import { Caption } from '@xo-union/tk-ui-typography';
import React from 'react';
import { NO_IMAGE_PATH } from '../../../../pages/Storefront/components/Media/constants';
import Style from './about-image.scss';

type AboutImageProps = {
	imageUrl?: string;
};

export const AboutImage: React.FC<AboutImageProps> = ({ imageUrl }) => {
	if (imageUrl) {
		return (
			<Img
				className={Style.spotlightImage}
				src={imageUrl}
				alt="Vendor"
				smartCrop
			/>
		);
	}

	return (
		<div className={Style.spotlightWrapper}>
			<div className={Style.bioPhoto}>
				<Img src={NO_IMAGE_PATH} alt="None" />
				<Caption size="sm">No photo yet</Caption>
			</div>
		</div>
	);
};
