import { useAppSelector } from '@redux/hooks';
import { awards as awardsSettings } from '@settings';
import { Body2, Overline } from '@xo-union/tk-ui-typography';
import React, { useMemo } from 'react';
import { AwardSingleItem } from '../../pages/Storefront/components/award-single-item/AwardSingleItem';
import withinDateRange from '../../utils/withinDateRange';
import Styles from './styles.scss';

const { startDate, endDate } = awardsSettings;

export const AboutSingleAward = () => {
	const vendor = useAppSelector((state) => state.vendor.vendor);

	const awards = new Set(vendor?.awards);

	const awardCount = useMemo(() => {
		return Array.from(awards).reduce((count, award) => {
			if (award === 'BOW2024' && !withinDateRange({ startDate, endDate })) {
				return count;
			}
			return count + 1;
		}, 0);
	}, [awards]);

	if (awardCount === 0 || !vendor) {
		return null;
	}
	return (
		<div className={Styles.awardsContainer}>
			<AwardSingleItem vendor={vendor} />
			<div>
				<Overline className={Styles.title} bold>
					AWARD WINNER ({awardCount}X)
				</Overline>
				<Body2>Thanks to recommendations from couples on The Knot</Body2>
			</div>
		</div>
	);
};
