import { Highlight } from '@components/about-spotlight/components/highlight';
import { useAppSelector } from '@redux/hooks';
import { useAtomValue } from 'jotai';
import React from 'react';
import {
	languagesAtom,
	totalMembersAtom,
	yearsInBusinessAtom,
} from '../../../jotai/storefront/detailed-pricing';
import DetailsHelper from '../../../pages/Storefront/containers/details/helpers';
import Styles from '../styles.scss';

export const Highlights = () => {
	const yearsInBusiness = useAtomValue(yearsInBusinessAtom);
	const totalMembers = useAtomValue(totalMembersAtom);
	const languages = useAtomValue(languagesAtom);
	const vendor = useAppSelector((state) => state.vendor.vendor);
	const flattenedDetails = DetailsHelper.detailsFlattener(vendor) as {
		'Business Attributes': string[];
	};
	const businessAtrributes = flattenedDetails['Business Attributes'];

	return (
		<div className={Styles.highlightsContainer}>
			<Highlight icon="vendors" text={`In business: ${yearsInBusiness}`} />
			<Highlight icon="guest_couple" text={`Team: ${totalMembers}`} />
			<Highlight icon="quick_reply" text={`Languages: ${languages}`} />
			{businessAtrributes?.length > 0 && (
				<Highlight
					icon="category-nlw"
					text={`Business: ${businessAtrributes.join(', ')}`}
				/>
			)}
		</div>
	);
};
