import Styles from '@components/footer_categories/styles.scss';
import { SmallCategoryCardV2 } from '@components/shared/SmallCategoryCard/small-category-card-v2';
import { useAppSelector } from '@redux/hooks';
import { getPageType } from '@redux/page';
import { H3 } from '@xo-union/tk-ui-typography';
import React from 'react';
import CategoriesConstants from '../../../constants/categories';
import { useStorefrontFeatureScannability } from '../../pages/Storefront/hooks/useStorefrontFeatureScannability';

interface FooterCategoriesV2Props {
	city: string;
	stateCode: string;
}
export const FooterCategoriesV2 = ({
	city,
	stateCode,
}: FooterCategoriesV2Props) => {
	const pageType = useAppSelector(getPageType);
	const shouldShowScannability = useStorefrontFeatureScannability();

	return (
		<div className={Styles.footerCategoriesContainer}>
			<H3>Wedding vendors in {city}</H3>
			<div className={Styles.grid}>
				{CategoriesConstants.TOP_TEN_CATEGORIES.map((category) => (
					<SmallCategoryCardV2
						key={category.code}
						category={category}
						pageType={pageType}
						city={city}
						stateCode={stateCode}
						fullWidth={shouldShowScannability}
					/>
				))}
			</div>
		</div>
	);
};
