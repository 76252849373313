import { Highlights } from '@components/about-spotlight/components/highlights';
import { Spotlight } from '@components/about-spotlight/components/spotlight';
import { useDetailedPricing } from '@hooks/use-detailed-pricing';
import { useLiteStorefront } from '@hooks/use-lite-storefront';
import React from 'react';
import Styles from './styles.scss';

export const AboutSpotlight = () => {
	const isDetailedPricing = useDetailedPricing();
	const isLiteStorefront = useLiteStorefront();

	if (!isDetailedPricing || isLiteStorefront) {
		return null;
	}

	return (
		<div className={Styles.container}>
			<Spotlight />
			<Highlights />
		</div>
	);
};
