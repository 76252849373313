import profileProperties from '@api/graphql/searchStorefrontFragment';
import axios from '@axios';
import { SearchConstants } from '@settings';
import type { Response } from '@typings/api';
import type { StateCodes } from '@typings/states';
import type { Raw as VendorRaw } from '@typings/vendor';
import { generateSeed } from '@utils/generateSeed';
import sortParam from '@utils/sortParam';

export const bowVendorSearchQuery = `
  query BowVendorSearch (
      $city: String,
      $filters: [String],
      $limit: Int,
      $page: Int,
      $seed: String
      $sort: [SortInput!],
      $state: String,
    ){
      search(
        filters: $filters,
        limit: $limit,
        location: { city: $city, state: $state },
        page: $page,
        seed: $seed,
        sort: $sort,
        fixedPageSize: true
      ) {
        profiles {
          ...ProfileProperties
        }
      }
    }
    ${profileProperties}
`;

export interface BowVendorSearchArgs {
	city: string;
	state: StateCodes;
	categoryGuid: string;
	limit: number;
	page?: number;
	seed?: string;
}

export interface BowVendorSearchResponse {
	search: {
		profiles: VendorRaw[];
	};
}

const checkForErrors = (response: Response<BowVendorSearchResponse>) => {
	if ('errors' in response && response.errors?.length) {
		throw new Error(response.errors[0].message);
	}
};

export const bowVendorSearch = async (args: BowVendorSearchArgs) => {
	const {
		city,
		state,
		categoryGuid,
		limit,
		page = 0,
		seed = generateSeed(),
	} = args;

	const data = {
		query: bowVendorSearchQuery,
		variables: {
			city,
			filters: [SearchConstants.BOW_CURRENT_WINNER_ID, categoryGuid],
			limit,
			page: page > 0 ? page - 1 : 0,
			seed,
			sort: sortParam('featured'),
			state,
		},
	};

	const response = await axios.post<Response<BowVendorSearchResponse>>(data);
	checkForErrors(response.data);

	return response.data.data.search.profiles || [];
};
