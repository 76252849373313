import { Overline } from '@xo-union/tk-ui-typography';
import React from 'react';
import Styles from './Calendar.scss';

export const MonthHeader = () => {
	return (
		<div className={Styles.monthHeader}>
			<Overline>Su</Overline>
			<Overline>Mo</Overline>
			<Overline>Tu</Overline>
			<Overline>We</Overline>
			<Overline>Th</Overline>
			<Overline>Fr</Overline>
			<Overline>Sa</Overline>
		</div>
	);
};
