import Styles from '@components/calendar/Calendar.scss';
import { Month } from '@components/calendar/month';
import classNames from 'classnames';
import React from 'react';
import { useDayPicker, useNavigation } from 'react-day-picker';

export const CalendarView = () => {
	const dayPicker = useDayPicker();
	const navigation = useNavigation();

	return (
		<>
			<div
				id={dayPicker.id}
				className={classNames(Styles.calendar, {
					[Styles.twoMonths]: navigation.displayMonths.length === 2,
				})}
			>
				<Month idx={0} displayMonth={navigation.displayMonths[0]} />
				{navigation.displayMonths.length === 2 && (
					<Month idx={1} displayMonth={navigation.displayMonths[1]} />
				)}
			</div>
		</>
	);
};
