import { HighlightsScannability } from '@components/highlights-scannability/highlights-scannability';
import { PriceAndGuestsHighlights } from '@components/pricing/price-and-guests-highlights/PriceAndGuestsHighlights';
import { useStorefrontFeatureBvcPlusPricing } from '@feature-flags/hooks/Storefront/useStorefrontFeatureBvcPlusPricing';
import { useDetailedPricing } from '@hooks/use-detailed-pricing';
import { useIsVenue } from '@hooks/use-is-venue';
import React from 'react';
import { useStorefrontFeatureScannability } from '../../pages/Storefront/hooks/useStorefrontFeatureScannability';
import { PhotographerHighlights } from './components/photographer-highlights';

export const AboutHighlights = () => {
	const isVenue = useIsVenue();
	const shouldShowScannability = useStorefrontFeatureScannability();
	const isDetailedPricing = useDetailedPricing();
	const isBvcPlusPricing = useStorefrontFeatureBvcPlusPricing();

	if (shouldShowScannability) {
		return <HighlightsScannability />;
	}

	if (isVenue) {
		return <PriceAndGuestsHighlights />;
	}

	if (isDetailedPricing || isBvcPlusPricing) {
		return <PhotographerHighlights />;
	}

	return null;
};
