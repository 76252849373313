import {
	desktopSize,
	mobileSize,
} from '@components/filmstrip-carousel/constants';
import { useDesktopMedia } from '@xo-union/tk-ui-breakpoints';
import { useMemo } from 'react';

export const useSlideWidth = () => {
	const desktopMedia = useDesktopMedia();

	return useMemo(() => {
		const isDesktop = desktopMedia.yes;
		return isDesktop ? desktopSize : mobileSize;
	}, [desktopMedia]);
};
